
import React from 'react';
import Link from 'next/link';

import styled from 'styled-components';
import breakpoints from 'styled-components-breakpoint';
import PropTypes from 'prop-types';
import Sanitized from 'react-sanitized';

import { Container } from 'react-bulma-components';
import Button from 'components/ButtonNormal';
import { withTranslation } from '../i18n';
import bugsnagClient from '../lib/bugsnag';


const ErrorWrapper = styled.div`
  padding-top: 100px;
  padding-bottom: 50px;
  min-height: calc(100vh - 202px);
  display: flex;
  align-items: center;
  justify-content: center;

  ${breakpoints('xs', 'desktop')`
    padding-top: 0;
    padding-bottom: 0;
  `}
`;

const NotFoundWrapper = styled.div`
  text-align: center;
  font-size: 25px;
  font-weight: lighter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${breakpoints('xs', 'mobile')`
    font-size: 22px;
  `}

  strong {
    line-height: 1;
    display: block;
    font-size: 220px;
    color: ${props => props.theme.danger};

    ${breakpoints('xs', 'mobile')`
      font-size: 120px;
    `}
  }

  .text-wrapper {
    margin-bottom: 20px;
  }
`;

const ErrorMessage = styled.div`
  text-align: center;
  font-size: 30px;
  font-weight: lighter;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${breakpoints('xs', 'mobile')`
    font-size: 25px;
  `}

  strong {
    line-height: 1;
    display: block;
    font-size: 220px;
    color: ${props => props.theme.danger};

    ${breakpoints('xs', 'mobile')`
      font-size: 120px;
    `}
  }

  .button {
    margin-top: 30px;
  }
`;

class Error extends React.Component {
  static getInitialProps(ctx) {
    const { res, err } = ctx;
    if (ctx.err) bugsnagClient.notify(ctx.err);

    let statusCode = null;
    if (res) {
      ({ statusCode } = res);
    } else if (err) {
      ({ statusCode } = err);
    }
    return {
      namespacesRequired: ['common'],
      statusCode,
      err,
    };
  }

  getErrorMessage() {
    const { t, statusCode, err } = this.props;
    console.log(err);

    switch (statusCode) {
      case 404:
        return t('error-404');
      case undefined:
        return t('error-without-status');
      default:
        return t('error-with-status', { statusCode });
    }
  }

  render() {
    const { statusCode, t } = this.props;
    const errorMsg = this.getErrorMessage();

    return (
      <ErrorWrapper>
        <Container className="column">
          {statusCode === 404 ? (
            <NotFoundWrapper>
              <Sanitized html={errorMsg} className="text-wrapper" />
              <Link href="/">
                <Button className="is-primary is-outlined">
                  {t('back-to-homepage')}
                </Button>
              </Link>
            </NotFoundWrapper>
          ) : (
            <ErrorMessage>
                <Sanitized html={errorMsg} />
                <Link href="/">
                  <Button className="is-primary is-outlined">
                    {t('back-to-homepage')}
                  </Button>
                </Link>
              </ErrorMessage>
          )}
        </Container>
      </ErrorWrapper>
    );
  }
}

Error.defaultProps = {
  statusCode: null,
};

Error.propTypes = {
  statusCode: PropTypes.number,
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(Error);
